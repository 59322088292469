<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.313 3.375H13.5V1.688C13.5 1.24049 13.3223 0.811291 13.0059 0.494759C12.6896 0.178226 12.2605 0.000265114 11.813 0H6.188C5.74031 0 5.31097 0.177842 4.9944 0.494404C4.67784 0.810965 4.5 1.24031 4.5 1.688V3.375H1.688C1.24031 3.375 0.810965 3.55284 0.494404 3.8694C0.177842 4.18597 0 4.61531 0 5.063L0 15.188C0 15.6357 0.177842 16.065 0.494404 16.3816C0.810965 16.6982 1.24031 16.876 1.688 16.876H16.313C16.7605 16.8757 17.1896 16.6978 17.5059 16.3812C17.8223 16.0647 18 15.6355 18 15.188V5.063C18 4.61549 17.8223 4.18629 17.5059 3.86976C17.1896 3.55323 16.7605 3.37527 16.313 3.375ZM6.75 2.25H11.25V3.375H6.75V2.25ZM12.375 10.969C12.3747 11.0434 12.345 11.1148 12.2924 11.1674C12.2398 11.22 12.1684 11.2497 12.094 11.25H10.125V13.219C10.1247 13.2934 10.095 13.3648 10.0424 13.4174C9.98977 13.47 9.91844 13.4997 9.844 13.5H8.156C8.08156 13.4997 8.01023 13.47 7.95759 13.4174C7.90495 13.3648 7.87526 13.2934 7.875 13.219V11.25H5.906C5.83156 11.2497 5.76023 11.22 5.70759 11.1674C5.65495 11.1148 5.62526 11.0434 5.625 10.969V9.281C5.62526 9.20655 5.65495 9.13524 5.70759 9.0826C5.76023 9.02995 5.83156 9.00026 5.906 9H7.875V7.031C7.87526 6.95655 7.90495 6.88524 7.95759 6.8326C8.01023 6.77995 8.08156 6.75026 8.156 6.75H9.844C9.91844 6.75026 9.98977 6.77995 10.0424 6.8326C10.095 6.88524 10.1247 6.95655 10.125 7.031V9H12.094C12.1684 9.00026 12.2398 9.02995 12.2924 9.0826C12.345 9.13524 12.3747 9.20655 12.375 9.281V10.969Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
